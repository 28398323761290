"use client";

import ImageAssets from "@/data/ImageAssets";
import { Locale } from "@/i18n";
import { Link, usePathname, useRouter } from "@/navigation";
import { useTranslations } from "next-intl";
import Image from "next/image";
import React, { useEffect } from "react";
import UTMCampaignBuilderUtils from "@/utils/UTMCampaignBuilderUtils";

const lightPages: string[] = [
  "^/security",
  "^/legal",
  "^/logout",
  "^/changelog",
  "^/comparisons$",
  "^/advertise",
  "^/about-us$",
  "^/perks",
];

function stickyHeader() {
  if (window.matchMedia("(min-width: 992px)").matches) {
    const stickyNav = document.querySelector(".header-sticky") as HTMLElement;
    document.addEventListener("scroll", function () {
      const scTop = window.scrollY || document.documentElement.scrollTop;
      if (scTop >= 400) {
        stickyNav?.classList.add("header-sticky-on");
      } else {
        stickyNav?.classList.remove("header-sticky-on");
      }
    });
  }
}

function Header({
  params,
  params: { locale },
}: {
  params: { locale: Locale };
}) {
  useEffect(stickyHeader, []);
  const pathname = usePathname();
  const router = useRouter();
  const t = useTranslations("navigation");


  const navbarTheme = lightPages.some((path) => pathname.match(path))
    ? "light"
    : "dark";


  const LANGUAGES = [
    {
      locale: "en",
      img: ImageAssets.UKFlag,
      label: 'English',
    },
    {
      locale: "bg",
      img: ImageAssets.BGFlag,
      label: 'Български',
    },
  ];

  return (
      <header
          className="header-absolute header-sticky"
          data-bs-theme={navbarTheme}
      >
        {/* Logo Nav START */}
        <nav className="navbar navbar-expand-xl">
          <div className="container">
            {/* Logo START */}
            <Link className="navbar-brand me-0" href={"/"} locale={locale}>
              <Image
                  className={`${navbarTheme}-mode-item`}
                  src={
                    navbarTheme === "light"
                        ? ImageAssets.DarkLogo
                        : ImageAssets.LightLogo
                  }
                  alt={t("company") + " logo"}
                  width={120}
                  height={30}
              />
            </Link>
            {/* Logo END */}
            {/* Main navbar START */}
            <div className="navbar-collapse collapse" id="navbarCollapse">
              <ul className="navbar-nav navbar-nav-scroll dropdown-hover mx-auto">

                {/*
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t("product_tour")}</a>

                  <div className="dropdown-menu dropdown-menu-center dropdown-menu-size-xl p-3">
                    <div className="row g-xl-3">
                      <div className="col-xl-4">
                        <ul className="list-unstyled">
                          <li className="dropdown-header h6">Portfolio Pages</li>
                          <li><a className="dropdown-item" href="#">Portfolio Masonry</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio Showcase</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio List</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio Case Study v.1</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio Case Study v.2</a></li>
                        </ul>
                      </div>
                      <div className="col-xl-4">
                        <ul className="list-unstyled">
                          <li className="dropdown-header h6">Portfolio Pages</li>
                          <li><a className="dropdown-item" href="#">Portfolio Masonry</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio Showcase</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio List</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio Case Study v.1</a></li>
                          <li><a className="dropdown-item" href="#">Portfolio Case Study v.2</a></li>
                        </ul>
                      </div>
                      <div className="col-xl-4 d-none d-xl-block">
                        <div className="d-flex gap-4">
                          <div className="card bg-transparent">
                            <picture>
                              <img src="https://assets.medentic.app/assets/images/portfolio/list/01.jpg" className="card-img" alt=""/>
                            </picture>
                            <div className="card-body px-0 text-start pb-0">
                              <a href="#" className="h6">Ceremony Worthy of Time</a>
                              <p className="mb-2 small">We help our customers better manage their web presence.</p>
                              <a className="icon-link icon-link-hover stretched-link mb-0" href="#">Learn more<i className="bi bi-arrow-right"></i> </a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </li>
                */}


                <li className="nav-item">
                  <Link className="nav-link" href={'/pricing'} locale={locale}>
                    {t("pricing")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" href={'/blog'} locale={locale}>
                    {t("blog")}
                  </Link>
                </li>
                {/*
                <li className="nav-item">
                  <a
                      className="nav-link"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => router.push("/")}
                  >
                    {t("resources")}
                  </a>
                </li>
                */}
              </ul>
            </div>
            {/* Main navbar END */}
            {/* Buttons */}
            <ul className="nav align-items-center dropdown-hover ms-sm-2">
              {/* Dark mode option START */}
              <li className="nav-item dropdown dropdown-animation">
                <button
                    className="btn mb-0 px-2 btn-link"
                    id="bd-theme"
                    type="button"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                >
                  <i className="bi bi-globe text-gray-900 fa-fw"></i>
                </button>
                <ul
                    className="dropdown-menu min-w-auto dropdown-menu-end"
                    aria-labelledby="bd-theme"
                    style={{width: "150px"}}
                >
                  {LANGUAGES.map((item) => (
                      <li className="mb-1" key={item.locale}>
                        <button
                            type="button"
                            className={`dropdown-item d-flex align-items-center ${item.locale == locale ? 'active' : ''}`}
                            onClick={() => router.replace(pathname, {locale: item.locale})}
                        >
                          <Image
                              className="fa-fw me-2"
                              src={item.img}
                              height="13"
                              width="30"
                              alt="language flag"
                          />
                          {item.label}
                        </button>
                      </li>
                  ))}

                </ul>
              </li>
              {/* Dark mode option END */}
              {/* Sign up button */}
              {/* Buy now button */}
              <li className="nav-item d-none d-sm-block">

                <Link
                    href={UTMCampaignBuilderUtils.generate(
                        process.env.NEXT_PUBLIC_APP_URL + "/v/register",
                        'www.medentica.app',
                        'website',
                        'website-header',
                        'free-registration'
                    )}
                    className="btn btn-sm btn-dark mb-0"
                    target="_blank"
                >
                  {t("register")}
                </Link>
              </li>
              <li className="nav-item me-2">
                <Link
                    href={UTMCampaignBuilderUtils.generate(
                        process.env.NEXT_PUBLIC_APP_URL + "/v/login",
                        'www.medentica.app',
                        'website',
                        'website-header',
                        'free-registration'
                    )}
                    target="_blank"
                    className="nav-link mb-0"
                >
                  {t("login")}
                </Link>
              </li>

              <li className="nav-item">
                <button
                    className="navbar-toggler ms-sm-3 p-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-animation">
                  <span/>
                  <span/>
                  <span/>
                </span>
                </button>
              </li>
            </ul>
          </div>
        </nav>
        {/* Logo Nav END */}
      </header>
  );
}

export default Header;
